import React, { useState, useEffect } from 'react'
import store from '../../../../store'
import classNames from 'classnames'
import { Props, IPersonalityData } from './types'
import View from '../../view'
import ProfileSection from '../../../organisms/profile-section'
import styles from './style.module.scss'

const ProfileSectionView: React.FC<Props> = ({ profile }) => {
  const [lottieName, setLottieName] = useState<string | undefined>(undefined)
  const [personalityData, setPersonalityData] =
    useState<IPersonalityData | undefined>(undefined)
  const introData = store((state) => state.introData)
  const uiData = store((state) => state.uiData)
  const personalitiesData = store((state) => state.personalitiesData)

  useEffect(() => {
    if (!profile) return
    setLottieName(
      introData.pages.find((d) => d.lottiePath && d.id === profile)?.lottiePath
    )
    setPersonalityData(
      personalitiesData.find((d) => d.id === profile)?.attributes
    )
    // eslint-disable-next-line
  }, [profile])

  const classes = classNames(styles['profile-section-view'], [
    'profile-section-view',
  ])

  return (
    <View extraClasses={classes}>
      {personalityData && lottieName && (
        <ProfileSection
          title={personalityData?.title}
          description={personalityData?.description}
          lottieName={lottieName}
          items={[
            {
              title: uiData['profile.specificStrength.label'],
              text: personalityData.specificStrength,
            },
            {
              title: uiData['profile.potential.label'],
              text: personalityData.potential,
            },
          ]}
        />
      )}
    </View>
  )
}

export default React.memo(ProfileSectionView)
