import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import Icon from "../../atoms/icon"

function CalloutBubble({ text }: Props) {
  const classes = classNames(styles['callout-bubble'])
  text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')

  return (
    <div className={classes}>
      <div className={styles['text-container']} dangerouslySetInnerHTML={{ __html: text }}></div>
      <Icon iconName={'triangle--blue'} extraClasses={styles['triangle-container']}/>
    </div>
  )
}

export default CalloutBubble
