import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import QuoteBubble from '../../molecules/quote-bubble'
import InspirationOverlayBase from '../inspiration-overlay-base'
import Icon from '../../atoms/icon'

function InspirationOverlayEvent({
  clickHandler,
  title,
  kicker,
  text,
  price,
  qrCode,
  qrCodeLabel,
  date,
  time,
  person,
  quote,
}: Props) {
  const classes = classNames(styles['inspiration-overlay-event'])

  return (
    <div className={classes} onClick={clickHandler}>
      <div className={styles['content-container']}>
        <div className={styles['event-container']}>
          <div className={styles['info-wrapper']}>
            {date && (
              <div className={styles['info-entry']}>
                <div className={styles['icon-container']}>
                  <Icon iconName="calendar-40" iconSize="medium"></Icon>
                </div>
                <div className={styles['event-date']}>{date}</div>
              </div>
            )}
            {time && (
              <div className={styles['info-entry']}>
                <div className={styles['icon-container']}>
                  <Icon iconName="time-40" iconSize="medium"></Icon>
                </div>
                <div className={styles['event-time']}>{time}</div>
              </div>
            )}
            {person && (
              <div className={styles['info-entry']}>
                <div className={styles['icon-container']}>
                  <Icon iconName="person-40" iconSize="medium"></Icon>
                </div>
                <div
                  className={styles['event-person']}
                  dangerouslySetInnerHTML={{ __html: person }}
                />
              </div>
            )}
          </div>
          {quote && (
            <div className={styles['quote']}>
              <QuoteBubble text={quote} isInverted={true} />
            </div>
          )}
        </div>
        <InspirationOverlayBase
          title={title}
          kicker={kicker}
          text={text}
          price={price}
          qrCode={qrCode}
          qrCodeLabel={qrCodeLabel}
        ></InspirationOverlayBase>
      </div>
    </div>
  )
}

export default InspirationOverlayEvent
