import React, {useEffect, useState} from 'react'
import store from "../../../store"
import RestartOverlay from "../../organisms/restart-overlay"

const IdleTimeOutHandler = (props: any)=>{
  const[,setShowModal]=useState(false)
  const setModalContent = store((state) => state.setModalContent)
  const timeOutInterval=props.timeOutInterval?props.timeOutInterval:60000
  const restartInterval=props.restartInterval?props.restartInterval:30000
  
  let timeoutTimer:any = undefined
  let restartTimer:any = undefined
  const events= ['click','scroll','load','mousemove','touchstart']
  
  const eventHandler =(eventType: any)=>{
    if(timeoutTimer){
      startTimer()
    }
  }
  
  useEffect(()=>{
    addEvents()

    return (()=>{
      removeEvents()
      clearTimeout(timeoutTimer)
      clearTimeout(restartTimer)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const startTimer=()=>{
    if(timeoutTimer) {
      clearTimeout(timeoutTimer)
    }
    
    timeoutTimer=setTimeout(() => {
      setShowModal(true)

      setModalContent(
          <RestartOverlay />,
          undefined,
          () => clearTimeout(restartTimer),
          'blue',
          'restart'
      )

      timeoutTimer=setTimeout(() => {
        document.location.href='/'
      }, restartInterval)
      
    },timeOutInterval)
  }
  
  const addEvents=()=>{
    events.forEach(eventName=>{
      window.addEventListener(eventName,eventHandler)
    })
    startTimer()
  }
  
  const removeEvents=()=>{
    events.forEach(eventName=>{
      window.removeEventListener(eventName,eventHandler)
    })
  }

  return(
      <div></div>
  )
}

export default IdleTimeOutHandler