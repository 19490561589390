import React from 'react'
import classNames from 'classnames'
import QRCode from 'react-qr-code'
import { Props } from './types'
import styles from './style.module.scss'

function InspirationOverlayBase({
  clickHandler,
  title,
  kicker,
  text,
  subline,
  price,
  qrCode,
  qrCodeLabel,
}: Props) {
  const classes = classNames(styles['inspiration-overlay-base'])

  return (
    <div className={classes} onClick={clickHandler}>
      <div className={styles.wrapper}>
        {kicker && <div className={styles.kicker}>{kicker}</div>}
        {title && <div className={styles.title}>{title}</div>}
        {text && (
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {subline && <div className={styles.subline}>{subline}</div>}
        {price && <div className={styles.price}>{price}</div>}
        {qrCode && (
          <div className={styles.qrcodeWrapper}>
            {/*@ts-ignore*/}
            <QRCode
              value={qrCode}
              bgColor="#ffffff"
              fgColor="#003cb4"
              size={88}
            />
            {qrCodeLabel && (
              <div className={styles.qrCodeLabel}>{qrCodeLabel}</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default InspirationOverlayBase
