import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function Stepper({ currentStep, totalSteps }: Props) {
  const classes = classNames(styles['stepper'])

  return (
    <div className={classes}>
      <div
        className={styles.progress}
        style={{ transform: `scaleY(${(currentStep / totalSteps) * 100}%)` }}
      />
    </div>
  )
}

export default Stepper
