import React, { useState } from 'react'
import classNames from 'classnames'
import { Props } from './types'
import AccordeonItem from './accordeon-item'
import styles from './style.module.scss'

function Accordeon({ extraClasses, items }: Props) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const accordeonClasses = classNames(styles.accordeon, extraClasses)

  return (
    <ul className={accordeonClasses}>
      {items.map((item, index) => (
        <AccordeonItem
          key={index}
          data={item}
          isOpen={index === activeIndex}
          clickHandler={() =>
            setActiveIndex((prevIndex) => (index === prevIndex ? null : index))
          }
        />
      ))}
    </ul>
  )
}

export default Accordeon
