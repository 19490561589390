import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function Icon({ iconName, iconSize, extraClasses }: Props) {
  const classes = classNames(styles['icon'], [
    styles[`size-${iconSize}`],
    extraClasses,
  ])
  let svgSource
  
  try {
    svgSource = require(`../../../assets/icons/${iconName}.svg`)
  } catch (e) {
    console.info('ICON NOT FOUND', iconName)
  }
  
  return (
    <div className={classes}>
      {svgSource &&
        <img src={svgSource} alt="" />
      }
    </div>
  )
}

export default Icon
