import { useEffect } from 'react'

function useOverlap(
  element: HTMLElement | null,
  targetSelector: string | undefined,
  onOverlap: (element: Element) => void
): boolean | undefined {
  useEffect(() => {
    window.addEventListener('scroll', checkOverlap)
    function checkOverlap() {
      if (!element || !targetSelector) return undefined
      const domRect = element.getBoundingClientRect()
      const domTargets = document.querySelectorAll(targetSelector)
      domTargets.forEach((target) => {
        const rect = target.getBoundingClientRect()
        if (
          !(
            domRect.top > rect.bottom ||
            domRect.right < rect.left ||
            domRect.bottom < rect.top ||
            domRect.left > rect.right
          )
        )
          onOverlap(target)
      })
    }
    return () => window.removeEventListener('scroll', checkOverlap)
    // eslint-disable-next-line
  }, [element, targetSelector])
  return undefined
}

export default useOverlap
