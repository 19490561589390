import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import Icon from '../../atoms/icon'

function EventTeaser({ text, date, time, sortIndex, clickHandler }: Props) {
  const classes = classNames(styles['event-teaser'], [
    'type-event',
    'isotope-item',
  ])

  return (
    <div className={classes} data-sort-index={sortIndex} onClick={clickHandler}>
      <div className={styles.top}>
        <div className={styles.header}>
          <div>{date}</div>
          <div>{time}</div>
        </div>
        <div className={styles['text-container']}>{text}</div>
      </div>
      <div className={styles['icon-container']}>
        <Icon iconName="arrow-40--blue" iconSize="medium" />
      </div>
    </div>
  )
}

export default EventTeaser
