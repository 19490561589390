import React from 'react'
import store from '../../../../store'
import classNames from 'classnames'
import { Props } from './types'
import View from '../../view'
import OnsiteSection from '../../../organisms/onsite-section'
import styles from './style.module.scss'

const OnsiteSectionView: React.FC<Props> = () => {
  const zonesData = store((state) => state.zonesData)
  const uiData = store((state) => state.uiData)
  const classes = classNames(styles['onsite-section-view'], [
    'onsite-section-view',
  ])

  return (
    <View extraClasses={classes}>
      <OnsiteSection title={uiData['onsite.title']} onsiteGroups={zonesData} />
    </View>
  )
}

export default React.memo(OnsiteSectionView)
