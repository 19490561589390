import React, { useEffect, useRef, useState } from 'react'
import Lottie from 'lottie-react'
import classNames from 'classnames'
import { Props } from './types'
import Accordeon from '../../atoms/accordeon'
import styles from './style.module.scss'

function ProfileSection({ title, description, items, lottieName }: Props) {
  const [ready, setReady] = useState<boolean>(false)
  const [animData, setAnimData] = useState<{} | undefined>(undefined)
  const lottieRef = useRef<any>(null)

  useEffect(() => {
    const _lottieRef = lottieRef.current
    setAnimData(require(`../../../assets/lotties/${lottieName}`))
    setTimeout(() => setReady(true), 250)
    return () => _lottieRef?.destroy()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    animData &&
      lottieRef.current.playSegments(
        [0, lottieRef.current.getDuration(true)],
        true
      )
  }, [animData])

  const classes = classNames(styles['profile-section'], ['results-section'], {
    [styles['ready']]: ready,
  })

  return (
    <div className={classes}>
      {animData && (
        <Lottie
          className={styles['lottie-anim']}
          lottieRef={lottieRef}
          animationData={animData}
          autoplay={false}
        />
      )}
      <div className={styles.right}>
        <span className={styles.title}>{title}</span>
        <div className={styles.description}>{description}</div>
        <div className={styles['accordion-wrapper']}>
          <Accordeon items={items} />
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProfileSection)
