import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

const Spinner: React.FC<Props> = () => {
  const classes = classNames(styles.spinner)
  return <div className={classes} />
}

export default Spinner
