import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import QuoteBubble from '../../molecules/quote-bubble'
import InspirationOverlayBase from '../inspiration-overlay-base'

function InspirationOverlayService({
  clickHandler,
  title,
  kicker,
  text,
  price,
  qrCode,
  qrCodeLabel,
  headline,
  quote,
}: Props) {
  const classes = classNames(styles['inspiration-overlay-service'])

  return (
    <div className={classes} onClick={clickHandler}>
      <div className={styles['content-container']}>
        <div className={styles['service-container']}>
          {headline && <div className={styles.headline}>{headline}</div>}
          {quote && (
            <div className={styles['quote']}>
              <QuoteBubble text={quote} isInverted={true} />
            </div>
          )}
        </div>
        <InspirationOverlayBase
          title={title}
          kicker={kicker}
          text={text}
          price={price}
          qrCode={qrCode}
          qrCodeLabel={qrCodeLabel}
        ></InspirationOverlayBase>
      </div>
    </div>
  )
}

export default InspirationOverlayService
