import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import Icon from '../../atoms/icon'

function ServiceTeaser({ text, sortIndex, clickHandler }: Props) {
  const classes = classNames(styles['service-teaser'], [
    'type-service',
    'isotope-item',
  ])

  return (
    <div className={classes} data-sort-index={sortIndex} onClick={clickHandler}>
      {text}
      <div className={styles['icon-container']}>
        <Icon iconName="arrow-40--white" />
      </div>
    </div>
  )
}

export default ServiceTeaser
