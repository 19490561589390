import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import Icon from '../../../atoms/icon'
function AccordeonItem({ extraClasses, data, isOpen, clickHandler }: Props) {
  const accordeonItemClasses = classNames(
    styles['accordeon-item'],
    { [styles['is-open']]: isOpen },
    extraClasses
  )

  return (
    <li className={accordeonItemClasses} onClick={clickHandler}>
      <span className={styles.title}>
        {data.title}
        <span className={styles.icon}>
          <Icon iconName="chevron-down-32" iconSize="small" />
        </span>
      </span>
      <span className={styles.text}>
        <p>{data.text}</p>
      </span>
    </li>
  )
}

export default AccordeonItem
