import React, { useRef, useState, useEffect } from 'react'
import store from '../../../store'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Props } from './types'
import { IInspirationData } from '../../../store/types'
import { IOption } from '../../atoms/filter-button/types'
import InspirationFilter from '../../molecules/inspiration-filter'
import BookTeaser from '../../molecules/book-teaser'
import BoutiqueArticleTeaser from '../../molecules/boutique-article-teaser'
import EventTeaser from '../../molecules/event-teaser'
import ServiceTeaser from '../../molecules/service-teaser'
import View from '../../views/view'
import InspirationOverlayBook from '../inspiration-overlay-book'
import InspirationOverlayProduct from '../inspiration-overlay-product'
import InspirationOverlayEvent from '../inspiration-overlay-event'
import InspirationOverlayService from '../inspiration-overlay-service'
import Masonry from '../../views/masonry'
import styles from './style.module.scss'

function InspirationSection({ lifeThemes, title, subline }: Props) {
  const navigate = useNavigate()
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const inspirationData = store((state) => state.inspirationData)
  const modalContent = store((state) => state.modalContent)
  const setModalContent = store((state) => state.setModalContent)
  const [selectedFilter, setSelectedFilter] = useState<IOption[]>([])
  const [sortedInspirationData, setSortedInspirationData] =
    useState<IInspirationData[] | null>(null)

  const modalContentSetter = (item: IInspirationData) =>
    setModalContent(
      item.type === 'book' ? (
        <InspirationOverlayBook
          title={item.attributes.title}
          kicker={item.attributes.subtitle}
          text={item.attributes.content}
          subline={item.attributes.productSubline}
          image={item.attributes.image.url}
          quote={item.attributes.quote}
          price={item.attributes.productPrice}
          qrCode={item.attributes.qrCodeUrl}
        />
      ) : item.type === 'product' ? (
        <InspirationOverlayProduct
          title={item?.attributes.title}
          kicker={item?.attributes.subtitle}
          text={item?.attributes.content}
          subline={item.attributes.productSubline}
          image={item?.attributes.image.url}
          quote={item?.attributes.quote}
          price={item?.attributes.productPrice}
          qrCode={item?.attributes.qrCodeUrl}
        />
      ) : item.type === 'event' ? (
        <InspirationOverlayEvent
          title={item?.attributes.title}
          kicker={item?.attributes.subtitle}
          text={item?.attributes.content}
          date={item?.attributes.eventDate}
          time={item?.attributes.eventTime}
          person={item?.attributes.eventPerson}
          quote={item?.attributes.quote}
          price={item?.attributes.productPrice}
          qrCode={item?.attributes.qrCodeUrl}
          qrCodeLabel={item?.attributes.qrCodeCTA}
        />
      ) : item.type === 'service' ? (
        <InspirationOverlayService
          title={item?.attributes.title}
          kicker={item?.attributes.subtitle}
          text={item?.attributes.content}
          quote={item?.attributes.quote}
          price={item?.attributes.productPrice}
          headline={item?.attributes.serviceDescription}
          qrCode={item?.attributes.qrCodeUrl}
          qrCodeLabel={item?.attributes.qrCodeCTA}
        />
      ) : null,
      `#inspiration-${item.id}`,
      () => navigate('/results'),
      'blue',
      'inspiration'
    )

  useEffect(() => {
    const timestamps = inspirationData
      .filter((item) => item.type === 'event')
      .map((item) => {
        const newDate = getDate(item)
        return {
          id: item.id,
          timestamp: newDate.getTime(),
        }
      })
    // const [minTR, maxTR] = [
    //   Math.min(...timestamps.map((t) => t.timestamp)),
    //   Math.max(...timestamps.map((t) => t.timestamp)),
    // ]
    setSortedInspirationData(() => {
      const eventCount = inspirationData.filter(
        (item) => item.type === 'event'
      ).length
      return inspirationData
        .map((item) => {
          return item.type === 'event'
            ? {
                ...item,
                sortIndex: (() => {
                  let sortIndex = 0
                  const timestamp = timestamps
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .find((t) => t.id === item.id)
                  if (timestamp)
                    sortIndex = (1 / eventCount) * timestamps.indexOf(timestamp)
                  return sortIndex
                })(),
              }
            : { ...item, sortIndex: Math.random() }
        })
        .sort((a, b) => a.sortIndex - b.sortIndex)
    })
    // eslint-disable-next-line
  }, [])

  function onSelected(options: IOption[]) {
    setSelectedFilter(options)
  }

  function getDate(item: any) {
      const date: number[] = item.attributes.eventDate
          .split('.')
          .map((k: any) => Number(k))
      return new Date(date[2], date[1] - 1, date[0])
  }

  const classes = classNames(styles['inspiration-section'], ['results-section'])
  const horizontallyScrollableViewClasses = classNames(
    styles['horizontal-scroll-container']
  )

  return (
    <View extraClasses={classes}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <p
          className={styles.subline}
          dangerouslySetInnerHTML={{ __html: `${subline} ` }}
        />
        <InspirationFilter
          options={[
            { label: 'Alle Inhalte', value: null },
            { label: 'Boutique Artikel', value: 'product' },
            { label: 'Forum Events', value: 'event' },
            { label: 'Dienstleistungen', value: 'service' },
          ]}
          onSelected={(option) => onSelected(option)}
        />
      </div>
      <View
        customRef={scrollContainerRef}
        extraClasses={horizontallyScrollableViewClasses}
        scrollableHorizontally={true}
      >
        {sortedInspirationData && lifeThemes && (
          <Masonry
            horizontal={true}
            filter={selectedFilter}
            containerRef={scrollContainerRef}
          >
            {sortedInspirationData
              .filter((item) => lifeThemes.includes(item.attributes.lifeTheme))
              .map((item) => {
                  const today = new Date()
                  today.setHours(0,0,0,0)
                return item.type === 'book' ? (
                  <BookTeaser
                    key={item.id}
                    title={item.attributes.title}
                    image={item.attributes.image.url.split('/').at(-1)}
                    sortIndex={item.sortIndex}
                    clickHandler={() =>
                      !modalContent.modal && modalContentSetter(item)
                    }
                  />
                ) : item.type === 'product' ? (
                  <BoutiqueArticleTeaser
                    key={item.id}
                    title={item.attributes.title}
                    image={item.attributes.image.url.split('/').at(-1)}
                    sortIndex={item.sortIndex}
                    clickHandler={() =>
                      !modalContent.modal && modalContentSetter(item)
                    }
                  />
                ) : item.type === 'event' && (getDate(item) >= today) ? (
                  <EventTeaser
                    key={item.id}
                    text={item.attributes.title}
                    date={item.attributes.eventDate}
                    time={item.attributes.eventTime}
                    sortIndex={item.sortIndex}
                    clickHandler={() =>
                      !modalContent.modal && modalContentSetter(item)
                    }
                  />
                ) : item.type === 'service' ? (
                  <ServiceTeaser
                    key={item.id}
                    text={item.attributes.serviceDescriptionShort}
                    sortIndex={item.sortIndex}
                    clickHandler={() =>
                      !modalContent.modal && modalContentSetter(item)
                    }
                  />
                ) : null
              })}
          </Masonry>
        )}
      </View>
    </View>
  )
}

export default React.memo(InspirationSection)
