import React, { useState, useEffect } from 'react'
import store from '../../../store'
import classNames from 'classnames'
import { Props } from './types'
import View from '../view'
import LoadingScreen from '../loading-screen'
import ProfileSection from './profile-section'
import InspirationSection from './inspiration-section'
import TeamSection from './team-section'
import OnsiteSection from './onsite-section'
import FooterSection from './footer-section'
import CloseButton from '../../atoms/close-button'
import RestartOverlay from '../../organisms/restart-overlay'
import styles from './style.module.scss'
import IdleTimeOutHandler from "../idle-timeout-handler";

const LoadingScreenText = 'Einen Moment, wir schauen in Ihre Zukunft'

const Results: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const profile = store((state) => state.profile)
  const modalContent = store((state) => state.modalContent)
  const setModalContent = store((state) => state.setModalContent)
  const classes = classNames(styles.results, ['results-view'])

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000)
  }, [])

  return (
    <View extraClasses={classes}>
      <IdleTimeOutHandler timeOutInterval={60000}
                          restartInterval={30000}
      />
      {profile && !isLoading && (
        <>
          <ProfileSection profile={profile} />
          <InspirationSection profile={profile} />
          <TeamSection profile={profile} />
          <OnsiteSection />
          <FooterSection />
          <CloseButton
            overlapCheckElSelector=".results-section"
            clickHandler={() =>
              !modalContent.modal &&
              setModalContent(
                <RestartOverlay />,
                undefined,
                undefined,
                'blue',
                'restart'
              )
            }
          />
        </>
      )}
      <LoadingScreen text={LoadingScreenText} show={isLoading} />
    </View>
  )
}

export default React.memo(Results)
