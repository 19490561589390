import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function ChatBubble({ text }: Props) {
  const [ready, setReady] = useState<boolean>(false)
  const textString = useRef<string[] | undefined>([])
  textString.current = text?.replace(/(?:\r\n|\r|\n)/g, ' <br/> ').split(' ')

  useEffect(() => {
    setTimeout(() => setReady(true), 1000)
  }, [])

  const classes = classNames(styles['chat-bubble'], {
    [styles['ready']]: ready,
  })

  return (
    <div className={classes}>
      {textString.current &&
        textString.current.map((letter: string, i) => {
          return letter === '<br/>' ? (
            <br />
          ) : (
            <span
              key={i}
              className={styles.letter}
              dangerouslySetInnerHTML={{ __html: `${letter} ` }}
            />
          )
        })}
    </div>
  )
}

export default ChatBubble
