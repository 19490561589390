import React, { useRef, useEffect, useState } from 'react'
import store from '../../../store'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { Props } from './types'
import View from '../view'
import CloseButton from '../../atoms/close-button'
import styles from './style.module.scss'

const Modal: React.FC<Props> = ({ extraClasses }) => {
  const navigate = useNavigate()
  const timer = useRef<NodeJS.Timeout | undefined>(undefined)
  const modalContent = store((state) => state.modalContent)
  const closeModal = store((state) => state.closeModal)
  const [renderContent, setRenderContent] = useState<React.ReactNode>(null)
  const classes = classNames(styles.modal, extraClasses)

  useEffect(() => {
    if (modalContent.modal && timer.current) {
      clearTimeout(timer.current)
      setRenderContent(modalContent.modal)
      modalContent.location && navigate(modalContent.location)
    } else {
      timer.current = setTimeout(() => setRenderContent(null), 500) // relies on CSSTransition timeout = 500
    }
    // eslint-disable-next-line
  }, [modalContent.modal])

  return (
    <CSSTransition
      appear={true}
      in={!!modalContent.modal}
      mountOnEnter={true}
      unmountOnExit={true}
      classNames={{
        appear: 'modal--appear',
        appearActive: 'modal--appear-active',
        appearDone: 'modal--appear-done',
        enter: 'modal--enter',
        enterActive: 'modal--enter-active',
        enterDone: 'modal--enter-done',
        exit: 'modal--exit',
        exitActive: 'modal--exit-active',
        exitDone: 'modal--exit-done',
      }}
      timeout={500}
    >
      <View extraClasses={classes}>
        <div className={styles.wrapper}>
          <CloseButton
            color={modalContent.closeButtonColor}
            showDelayMs={250}
            clickHandler={() => closeModal(modalContent.closeAction)}
            extraClasses={modalContent.closeButtonInset}
          />
          {renderContent}
        </div>
      </View>
    </CSSTransition>
  )
}

export default React.memo(Modal)
