import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import QuoteBubble from '../../molecules/quote-bubble'
import InspirationOverlayBase from '../inspiration-overlay-base'

function InspirationOverlayProduct({
  clickHandler,
  title,
  kicker,
  text,
  subline,
  price,
  qrCode,
  image,
  quote,
}: Props) {
  const classes = classNames(styles['inspiration-overlay-product'])
  let productImage

  try {
    productImage = image
      ? require(`../../../assets/img/inspiration/${image?.split('/').at(-1)}`)
      : null
  } catch (e) {
    console.error(e)
  }

  return (
    <div className={classes} onClick={clickHandler}>
      <div className={styles['content-container']}>
        <div className={styles['product-container']}>
          {productImage && (
            <div className={styles['image-container']}>
              <img className={styles.image} src={productImage} alt="" />
            </div>
          )}
          {quote && (
            <div className={styles['quote']}>
              <QuoteBubble text={quote} />
            </div>
          )}
        </div>
        <InspirationOverlayBase
          title={title}
          kicker={kicker}
          text={text}
          subline={subline}
          price={price}
          qrCode={qrCode}
        />
      </div>
    </div>
  )
}

export default InspirationOverlayProduct
