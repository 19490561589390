import React from 'react'
import classNames from 'classnames'
import styles from './style.module.scss'
import { IZonesData } from '../../../store/types'
import OnsiteTeaser from '../../molecules/onsite-teaser'

function OnsiteGroup({
  lead,
  description,
  image,
  momentsGenerosities,
}: IZonesData['attributes']) {
  const classes = classNames(styles['onsite-group'], 'onsite-group')

  return (
    <div className={classes}>
      <OnsiteTeaser
        title={lead}
        description={description}
        image={image['url']}
        momentsGenerosities={momentsGenerosities}
      />
    </div>
  )
}

export default OnsiteGroup
