import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function FilterButton({ option, isDisabled, isSelected, clickHandler }: Props) {
  const classes = classNames(styles['filter-button'], {
    [styles['is-disabled']]: isDisabled,
    [styles['is-selected']]: isSelected,
  })

  return (
    <div className={classes}>
      <div
        className={styles.label}
        onClick={() => clickHandler && clickHandler(option)}
      >
        {option.label}
      </div>
    </div>
  )
}

export default FilterButton
