import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

const View: React.FC<Props> = ({
  extraClasses,
  children,
  scrollableHorizontally,
  scrollableVertically,
  customRef,
}) => {
  const classes = classNames(styles.view, extraClasses, {
    [styles.scrollable]: scrollableHorizontally || scrollableVertically,
    [styles['scrollable--horizontally']]: scrollableHorizontally,
    [styles['scrollable--vertically']]: scrollableVertically,
  })

  return (
    <div ref={customRef} className={classes}>
      {children}
    </div>
  )
}

export default React.memo(View)
