import React, { useState } from 'react'
import store from '../../../../store'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Props } from './types'
import View from '../../view'
import Button from '../../../atoms/button'
import ChatBubble from '../../../molecules/chat-bubble'
import AnswerMultiSelect from '../../../molecules/answer-multi-select'
import styles from './style.module.scss'

const ChatSlide: React.FC<Props> = ({ index, data, extraClasses }) => {
  const navigate = useNavigate()
  const addResult = store((state) => state.addResult)
  const uiData = store((state) => state.uiData)
  const lifeThemesData = store((state) => state.lifeThemesData)
  const [multiselectSelected, setMultiselectSelected] = useState<
    (string | undefined)[]
  >([])
  const classes = classNames(styles['chat-slide'], [extraClasses])

  return (
    <View extraClasses={classes} type="chat">
      <div className={styles['chat-bubble']}>
        <ChatBubble text={data?.attributes?.question} />
      </div>
      <div className={styles['button-group']}>
        {data?.type === 'intro' && (
          <Button
            type="primary"
            label={uiData['chat.intro.cta.label'] || ''}
            clickHandler={() => navigate(`/chat#${index + 1}`)}
          />
        )}
        {data?.type === 'question-single' && (
          <>
            <Button
              type="primary"
              label={data?.attributes.answer1Label || ''}
              clickHandler={() => {
                addResult(index - 1, data?.attributes.answer1Value)
                navigate(`/chat#${index + 1}`)
              }}
            />
            <Button
              type="primary"
              label={data?.attributes.answer2Label || ''}
              clickHandler={() => {
                addResult(index - 1, data?.attributes.answer2Value)
                navigate(`/chat#${index + 1}`)
              }}
            />
          </>
        )}
        {data?.type === 'question-life-theme' && (
          <div className={styles['flex-column']}>
            <AnswerMultiSelect
              answers={lifeThemesData.map((lt) => ({
                label: lt.attributes.value,
                value: lt.id,
              }))}
              maxItems={2}
              onSelect={(v) => setMultiselectSelected(v)}
            />
            <Button
              type="primary"
              label="Zeigen Sie mir meine Zukunft"
              isDisabled={multiselectSelected.length < 1}
              clickHandler={() => {
                addResult(index - 1, multiselectSelected)
                navigate(`/results`)
              }}
            />
          </div>
        )}
      </div>
    </View>
  )
}

export default React.memo(ChatSlide)
