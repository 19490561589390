import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function Pictogram({ name, size }: Props) {
  const classes = classNames(styles['pictogram'], [styles[`size-${size}`]])
  let svgSource

  try {
    svgSource = require(`../../../assets/pictograms/${name}.svg`)
  } catch (e) {
    console.info('PICTOGRAM NOT FOUND', name)
  }

  return (
    <div className={classes}>{svgSource && <img src={svgSource} alt="" />}</div>
  )
}

export default Pictogram
