import create from 'zustand'
import { devtools } from 'zustand/middleware'
import IStore from './types'

const useStore = create<IStore>(
  devtools((set) => ({
    mainNavPages: ['/', 'chat'], // todo: derive from some json
    introSlideNum: 0,
    setIntroSlideNum: (pageNum) =>
      set(() => ({
        introSlideNum: pageNum,
      })),
    introData: (() => require('../assets/data/introData.json'))(),
    chatData: (() => require('../assets/data/chatData.json'))(),
    generosityData: (() => require('../assets/data/generosityData.json'))(),
    personalitiesData: (() =>
      require('../assets/data/personalitiesData.json'))(),
    inspirationData: (() => require('../assets/data/inspirationData.json'))(),
    zonesData: (() => require('../assets/data/zonesData.json'))(),
    lifeThemesData: (() => require('../assets/data/lifeThemesData.json'))(),
    uiData: (() => require('../assets/data/uiData.json'))(),
    profilesMap: (() => require('../assets/data/profilesMap.json'))(),
    results: [],
    setResults: (results) =>
      set((state) => {
        state.calcProfile(results)
        return { results }
      }),
    addResult: (index, value) =>
      set((state) => {
        const newResultArray = [...state.results]
        state.results[index]
          ? (newResultArray[index] = value)
          : newResultArray.push(value)
        state.calcProfile(newResultArray)
        return { results: newResultArray }
      }),
    profile: undefined,
    calcProfile: (results) =>
      set((state) => {
        if (!results) results = state.results
        if (results.length <= 6) return { profile: undefined }
        const chatResults = [...results].slice(0, 6).join('')
        const newProfile = state.profilesMap[chatResults]
        return {
          profile: newProfile,
        }
      }),
    modalContent: {
      modal: undefined,
      location: undefined,
      closeAction: undefined,
      closeButtonColor: undefined,
      closeButtonInset: undefined,
    },
    setModalContent: (
      modal,
      location,
      closeAction,
      closeButtonColor,
      closeButtonInset
    ) =>
      set(() => ({
        modalContent: {
          modal,
          location,
          closeAction,
          closeButtonColor,
          closeButtonInset,
        },
      })),
    closeModal: (callback?) =>
      set((state) => {
        const modalContent = { ...state.modalContent }
        modalContent.modal = undefined
        if (callback) callback()
        return { modalContent }
      }),
  }))
)

export default useStore
