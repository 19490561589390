import React, { useRef, useEffect, useLayoutEffect } from 'react'
import classNames from 'classnames'
import isotope from 'isotope-layout'
import Isotope from 'isotope-layout'
import { Props } from './types'
import styles from './style.module.scss'

const MasonryView: React.FC<Props> = ({
  filter,
  horizontal,
  children,
  containerRef,
}) => {
  const isoContainer = useRef<HTMLDivElement>(null)
  const iso = useRef<Isotope | null>(null)

  useLayoutEffect(() => {
    if (horizontal) require('isotope-masonry-horizontal')
    createIso()
    return () => iso.current?.destroy()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const filterClass =
      filter?.length &&
      filter?.[0].value &&
      filter?.map((f) => `.type-${f.value}`).join(', ')
    iso.current?.arrange({
      filter: filterClass || '*',
    })
    containerRef?.current?.scroll(0, 0)
    // eslint-disable-next-line
  }, [filter])

  function createIso(typeSelector?: string) {
    if (!isoContainer.current || !children) return
    const shortestElementHeight = 10 // isoContainer.current?.querySelector('.type-product')?.clientHeight
    iso.current = new isotope(isoContainer.current, {
      resize: false,
      itemSelector: `.isotope-item${typeSelector ? typeSelector : ''}`,
      layoutMode: horizontal ? 'masonryHorizontal' : 'masonry',
      // @ts-ignore
      masonryHorizontal: horizontal
        ? {
            rowHeight: shortestElementHeight,
            gutter: 56,
          }
        : null,
      // getSortData: {
      //   sortIndex: '[data-sort-index]',
      // },
      // sortBy: 'sortIndex',
    })
  }

  const classes = classNames(styles.masonry, {
    [styles.horizontal]: horizontal,
  })

  return (
    <div ref={isoContainer} className={classes}>
      {children}
    </div>
  )
}

export default React.memo(MasonryView)
