import { useEffect } from 'react'
import store from './../store'

function useUpdateResults(results) {
  const setResults = store((state) => state.setResults)
  const storedResults = window.localStorage.getItem('results')

  useEffect(() => {
    if (storedResults) {
      const storedResultsArray = JSON.parse(storedResults)
      if (Array.isArray(storedResultsArray) && storedResultsArray.length) {
        setResults(storedResultsArray)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (results.length) {
      const newResults = JSON.stringify(results)
      if (storedResults !== newResults) {
        window.localStorage.setItem('results', newResults)
      }
    }
    // eslint-disable-next-line
  }, [results])

  return results
}

export default useUpdateResults
