import React from 'react'
import store from '../../../../store'
import classNames from 'classnames'
import { Props } from './types'
import View from '../../view'
import styles from './style.module.scss'
import InspirationSection from '../../../organisms/inspiration-section'

const InspirationSectionView: React.FC<Props> = () => {
  const results = store((state) => state.results)
  const uiData = store((state) => state.uiData)
  const lifeThemes = results[results.length - 1]
  const classes = classNames(styles['inspiration-section-view'], [
    'inspiration-section-view',
  ])

  return (
    <View extraClasses={classes}>
      <InspirationSection
        lifeThemes={lifeThemes as string[]}
        title={uiData['inspiration.title']}
        subline={uiData['inspiration.subline']}
      />
    </View>
  )
}

export default React.memo(InspirationSectionView)
