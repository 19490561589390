import React, { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import Lottie from 'lottie-react'
import classNames from 'classnames'
import { Props } from './types'
import View from '../view'
import Spinner from '../../atoms/spinner'
import styles from './style.module.scss'

const animDataBg = require('../../../assets/lotties/intro/00_sterne.json')

const Results: React.FC<Props> = ({ show, text }) => {
  const bgLottieRef = useRef() as any
  const navigate = useNavigate()
  const classes = classNames(styles['loading-screen'])

  useEffect(() => {
    navigate(show ? '#loading' : '/results')
    // eslint-disable-next-line
  }, [show])

  return (
    <CSSTransition
      timeout={1000}
      in={show}
      mountOnEnter={true}
      unmountOnExit={true}
      classNames={{
        enter: styles['enter'],
        enterActive: styles['enter-active'],
        enterDone: styles['enter-done'],
        exit: styles['exit'],
        exitActive: styles['exit-active'],
        exitDone: styles['exit-done'],
      }}
    >
      <View extraClasses={classes}>
        <Lottie
          className={styles['lottie-anim--stars']}
          lottieRef={bgLottieRef}
          animationData={animDataBg}
          loop={false}
        />
        <div className={styles.spinner}>
          <Spinner />
        </div>
        <p className={styles.text}>{text}</p>
      </View>
    </CSSTransition>
  )
}

export default React.memo(Results)
