import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function ButtonSelect({ label, clickHandler, isDisabled, isSelected }: Props) {
  const classes = classNames(styles['button-select'], {
    [styles['is-disabled']]: isDisabled,
    [styles['is-selected']]: isSelected,
  })

  return (
    <div className={classes}>
      <div
        className={styles.label}
        onClick={() => clickHandler && clickHandler(label)}
      >
        {label}
      </div>
    </div>
  )
}

export default ButtonSelect
