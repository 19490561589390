import React, { useEffect, useRef } from 'react'
import store from '../../../store'
import { useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import usePrevious from '../../../hooks/usePrevious'
import classNames from 'classnames'
import Lottie from 'lottie-react'
import { Props } from './types'
import View from '../view'
import CloseButton from '../../atoms/close-button'
import ChatSlide from './chat-slide'
import Stepper from '../../molecules/stepper'
import styles from './style.module.scss'
import RestartOverlay from '../../organisms/restart-overlay'
import IdleTimeOutHandler from "../idle-timeout-handler";

let bgLottieSegments: number[][]

const Chat: React.FC<Props> = () => {
  const location = useLocation()
  const lottieRef = useRef<any>(null)
  const index = parseInt(location.hash.replace('#', '')) || 0
  const prevIndex = usePrevious(index)
  const chatData = store((state) => state.chatData)
  const setModalContent = store((state) => state.setModalContent)

  useEffect(() => {
    const _lottieRef = lottieRef.current
    const totalFrames = _lottieRef.getDuration(true)
    const segmentCount = chatData.length + 1
    bgLottieSegments = new Array(segmentCount)
      .fill(null)
      .map((s, i) => [
        Math.floor(i * (totalFrames / segmentCount)),
        Math.floor(
          i * (totalFrames / segmentCount) + totalFrames / segmentCount
        ),
      ])
    return () => _lottieRef?.destroy()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const nextSegment =
      prevIndex === undefined || prevIndex < index
        ? bgLottieSegments[index]
        : [...bgLottieSegments[prevIndex]].reverse()
    lottieRef.current.setSpeed(0.75)
    if (location.pathname === '/chat')
      setTimeout(() => lottieRef.current.playSegments(nextSegment, true), 125)
    // eslint-disable-next-line
  }, [index])

  const classes = classNames(styles.chat)

  return (
    <View extraClasses={classes}>
        <IdleTimeOutHandler timeOutInterval={20000} 
                            restartInterval={10000}
        />
      <Lottie
        className={styles['lottie-anim--stars']}
        lottieRef={lottieRef}
        animationData={require('../../../assets/lotties/stars-bg.json')}
        loop={false}
        autoplay={false}
      />
      <div className={styles['stepper']}>
        <Stepper totalSteps={chatData.length} currentStep={index + 1} />
      </div>
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.hash}
          classNames="chat-pagination"
          appear={true}
          timeout={2000}
        >
          <ChatSlide
            key={location.hash}
            index={index}
            data={chatData[index]}
            extraClasses={
              index === chatData.length - 1
                ? 'is-last-slide'
                : index === 0
                ? 'is-first-slide'
                : ''
            }
          />
        </CSSTransition>
      </TransitionGroup>
      <CloseButton
        clickHandler={() =>
          setModalContent(
            <RestartOverlay />,
            undefined,
            undefined,
            'blue',
            'restart'
          )
        }
      />
    </View>
  )
}

export default React.memo(Chat)
