import React, { useRef, useEffect } from 'react'
import store from '../../../../store'
import { CSSTransition } from 'react-transition-group'
import Lottie from 'lottie-react'
import classNames from 'classnames'
import { Props } from './types'
import View from '../../view'
import styles from './style.module.scss'

const IntroSlide: React.FC<Props> = ({
  pageNum,
  title,
  animData,
  animDataBg,
  extraClasses,
}) => {
  const lottieRef = useRef() as any
  const bgLottieRef = useRef() as any
  const globalIntroSlideNum = store((state) => state.introSlideNum)

  useEffect(() => {
    const _lottieRef = lottieRef.current
    const _bgLottieRef = bgLottieRef.current
    return () => {
      _lottieRef?.destroy()
      _bgLottieRef?.destroy()
    }
  }, [])

  const classes = classNames(styles['intro-slide'], [
    styles[`page-${pageNum === 0 ? 'title' : pageNum % 2 ? 'even' : 'odd'}`],
    extraClasses,
  ])

  return (
    <View extraClasses={classes}>
      <div className={styles.bg} />
      {animDataBg && (
        <Lottie
          className={styles['lottie-anim--stars']}
          lottieRef={bgLottieRef}
          animationData={animDataBg}
          loop={true}
        />
      )}
      <CSSTransition
        appear={true}
        in={pageNum === globalIntroSlideNum}
        classNames={{
          appear: styles['text--appear'],
          appearActive: styles['text--appear-active'],
          appearDone: styles['text--appear-done'],
          enter: styles['text--enter'],
          enterActive: styles['text--enter-active'],
          enterDone: styles['text--enter-done'],
          exit: styles['text--exit'],
          exitActive:
            pageNum === 0
              ? styles['text--exit-active-first']
              : styles['text--exit-active'],
          exitDone:
            pageNum === 0
              ? styles['text--exit-done-first']
              : styles['text--exit-done'],
        }}
        timeout={2000}
      >
        <label className={styles['text']}>
          {Array.isArray(title) ? (
            title.map((t, i) => <span key={i}>{t}</span>)
          ) : (
            <span>{title}</span>
          )}
        </label>
      </CSSTransition>
      {animData && (
        <Lottie
          className={styles['lottie-anim']}
          lottieRef={lottieRef}
          animationData={animData}
          loop={true}
        />
      )}
    </View>
  )
}

export default React.memo(IntroSlide)
