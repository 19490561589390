import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Props } from './types'
import ButtonSelect from '../../atoms/button-select'
import styles from './style.module.scss'

function AnswerMultiSelect({ answers, maxItems, onSelect }: Props) {
  const classes = classNames(styles['answer-multiselect'])
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  useEffect(() => {
    onSelect &&
      onSelect(
        selectedItems.map((i) => {
          const result = answers.find((a) => a.label === i)
          return result?.value
        })
      )
    // eslint-disable-next-line
  }, [selectedItems])

  return (
    <div className={classes}>
      {answers.map((answer, i) => (
        <ButtonSelect
          key={i}
          label={answer.label}
          isSelected={selectedItems.includes(answer.label as string)}
          isDisabled={
            selectedItems.length >= maxItems &&
            !selectedItems.includes(answer.label as string)
          }
          clickHandler={(item) =>
            setSelectedItems((prevSelectedItems) =>
              prevSelectedItems.includes(item)
                ? prevSelectedItems.filter((v) => v !== item)
                : prevSelectedItems.concat(item)
            )
          }
        />
      ))}
    </div>
  )
}

export default AnswerMultiSelect
