import React, { useRef, useState, useEffect } from 'react'
import store from '../../../store'
import { useNavigate } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { Props } from './types'
import View from '../view'
import IntroSlide from './intro-slide'
import Button from '../../atoms/button'
import styles from './style.module.scss'

let interval: NodeJS.Timer

const Intro: React.FC<Props> = () => {
  const navigate = useNavigate()
  const introData = store((state) => state.introData)
  const animDataArray = useRef<({ animData: {}; animDataBg?: {} } | null)[]>([])
  const time = useRef<number>(0)
  const setGlobalIntroSlideNum = store((state) => state.setIntroSlideNum)
  const [introSlideNum, setIntroSlideNum] = useState<number>(0)

  useEffect(() => {
    if (introData) {
      animDataArray.current = introData.pages.map((d) => {
        return {
          animData: d.lottiePath
            ? require(`../../../assets/lotties/${d.lottiePath}`)
            : null,
          animDataBg: d.lottiePathBg
            ? require(`../../../assets/lotties/${d.lottiePathBg}`)
            : null,
        }
      })
    }
    interval = setInterval(() => {
      time.current += 1
      checkTime()
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setGlobalIntroSlideNum(introSlideNum)
    // eslint-disable-next-line
  }, [introSlideNum])

  function checkTime() {
    if (time.current === 7) {
      setIntroSlideNum((introSlideNum) =>
        introSlideNum + 1 === introData.pages.length ? 0 : introSlideNum + 1
      )
      time.current = 0
    }
  }

  const classes = classNames(styles.intro, [
    styles[`page--${introSlideNum % 2 ? 'even' : 'odd'}`],
    styles[`page-num--${introSlideNum}`],
  ])

  const startButtonClasses = classNames(styles['start-button'])
  const titleClasses = classNames(styles['title'], {
    [styles['title--show']]: introSlideNum > 0,
  })

  return (
    <View extraClasses={classes}>
      <TransitionGroup component={null}>
        <CSSTransition
          key={introSlideNum}
          classNames={{
            enter: styles['slide--enter'],
            exit: styles['slide--exit'],
            enterActive: styles['slide--enter-active'],
            enterDone: styles['slide--enter-done'],
            exitActive: styles['slide--exit-active'],
            exitDone: styles['slide--exit-done'],
          }}
          timeout={1000}
        >
          <IntroSlide
            pageNum={introSlideNum}
            title={introData.pages[introSlideNum].title}
            animData={animDataArray.current[introSlideNum]?.animData}
            animDataBg={animDataArray.current[introSlideNum]?.animDataBg}
            extraClasses={styles.slide}
          />
        </CSSTransition>
      </TransitionGroup>
      <div className={startButtonClasses}>
        <Button
          type="primary"
          label={introData.button.label}
          clickHandler={() => {
            navigate('/chat')
            clearInterval(interval)
          }}
        />
      </div>
      <h1 className={titleClasses}>{introData.title}</h1>
    </View>
  )
}

export default React.memo(Intro)
