import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import View from '../../view'
import styles from './style.module.scss'
import FooterSection from '../../../organisms/footer-section'

const FooterSectionView: React.FC<Props> = () => {
  const classes = classNames(styles['footer-section-view'], [
    'footer-section-view',
  ])

  return (
    <View extraClasses={classes}>
      <FooterSection />
    </View>
  )
}

export default React.memo(FooterSectionView)
