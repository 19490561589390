import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { Props } from './types'
import Pictogram from '../../atoms/pictogram'
import styles from './style.module.scss'

function GenerosityOverlay({ title, description, pictogramName }: Props) {
  const [isReady, setIsReady] = useState(false)
  const classes = classNames(styles['generosity-overlay'])

  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <div className={classes}>
      <CSSTransition
        in={isReady}
        appear={true}
        timeout={1000}
        classNames="generosity-overlay-"
      >
        <div className={styles.bubble}>
          <Pictogram name={pictogramName} size="large" />
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default GenerosityOverlay
