import React from 'react'
import store from '../../../store'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import Button from '../../atoms/button'

function RestartOverlay({ extraClasses }: Props) {
  const uiData = store((state) => state.uiData)
  const closeModal = store((state) => state.closeModal)
  const classes = classNames(styles['restart-overlay'], extraClasses)

  return (
    <div className={classes}>
      <div className={styles['content-container']}>
        <div className={styles['title']}>{uiData['overlay.restart.title']}</div>
        <div className={styles['text']}>
          {uiData['overlay.restart.description']}
        </div>
      </div>
      <div className={styles['button-container']}>
        <Button
          type="secondary"
          label={uiData['general.cancel.label']}
          clickHandler={() => closeModal()}
        />
        <Button
          type="primary"
          label={uiData['general.restart.label']}
          clickHandler={() => (document.location.href = '/')}
        />
      </div>
    </div>
  )
}

export default RestartOverlay
