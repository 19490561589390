import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Props } from './types'
import { TCloseButtonColor } from '../../../store/types'
import Icon from '../icon'
import useOverlap from '../../../hooks/useOverlap'
import styles from './style.module.scss'

function CloseButton({
  color,
  overlapCheckElSelector,
  clickHandler,
  showDelayMs,
  extraClasses,
}: Props) {
  const [_color, _setColor] = useState<TCloseButtonColor | undefined>('white')
  const [isReady, setIsReady] = useState(false)
  const [el, setEl] = useState<HTMLElement | null>(null)
  const classes = classNames(
    styles['close-button'],
    { [styles['show-delayed']]: !!showDelayMs },
    { [styles['show']]: isReady },
    extraClasses
  )

  useOverlap(el, overlapCheckElSelector, (target) => {
    const bgColor = window
      .getComputedStyle(target)
      .backgroundColor.replaceAll(' ', '')
    _setColor(bgColor === 'rgb(255,255,255)' ? 'blue' : 'white')
  })

  useEffect(() => {
    const timer = !!showDelayMs
      ? setTimeout(() => setIsReady(true), showDelayMs)
      : undefined
    return () => timer && clearTimeout(timer)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    _setColor(color)
  }, [color])

  return (
    <button ref={(el) => setEl(el)} className={classes} onClick={clickHandler}>
      <Icon
        iconName={_color === 'blue' ? 'close-50--blue' : 'close-50--white'}
      />
    </button>
  )
}

export default React.memo(CloseButton)
