import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'
import CalloutBubble from '../../molecules/callout-bubble'

function TeamSection({
  clickHandler,
  title,
  teamMessage1,
  teamMessage2,
  imgSource,
}: Props) {
  const image = require(`../../../assets/img/team/${imgSource}`)
  const personalizedText = `${teamMessage1}\n\n${teamMessage2}`
  const classes = classNames(styles['team-section'], ['results-section'])

  return (
    <div className={classes} onClick={clickHandler}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={styles.grid}>
        <figure>
          <img src={image} alt="" />
        </figure>
        <CalloutBubble text={personalizedText} />
      </div>
    </div>
  )
}

export default TeamSection
