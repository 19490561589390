import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import GenerosityTeaser from '../../molecules/generosity-teaser'
import styles from './style.module.scss'

function OnsiteTeaser({
  clickHandler,
  title,
  description,
  image,
  momentsGenerosities,
}: Props) {
  const classes = classNames(styles['onsite-teaser'])
  let imgSource

  try {
    imgSource = image
      ? (imgSource = require(`../../../assets/img/onsite/${image
          ?.split('/')
          .at(-1)}`))
      : null
  } catch (error) {
    console.info('IMAGE NOT FOUND', image)
  }

  return (
    <div className={classes} onClick={clickHandler}>
      <div className={styles['image-container']}>
        {imgSource && <img src={imgSource} alt="" />}
        <div className={styles['generosity-teaser-wrapper']}>
          {momentsGenerosities?.map((object) => (
            <GenerosityTeaser key={object} title={object} />
          ))}
        </div>
      </div>
      <div className={styles['content-container']}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{description}</p>
      </div>
    </div>
  )
}

export default OnsiteTeaser
