import React from 'react'
import store from '../../../../store'
import classNames from 'classnames'
import { Props, IPersonalityData } from './types'
import View from '../../view'
import styles from './style.module.scss'
import TeamSection from '../../../organisms/team-section'

const TeamSectionView: React.FC<Props> = ({ profile }) => {
  const personalitiesData = store((state) => state.personalitiesData)
  const personalityData: IPersonalityData | undefined = personalitiesData.find(
    (d) => d.id === profile
  )?.attributes
  const uiData = store((state) => state.uiData)
  const classes = classNames(styles['team-section-view'], ['team-section-view'])

  return (
    <View extraClasses={classes}>
      {personalityData && (
        <TeamSection
          title={uiData['team.title']}
          teamMessage1={personalityData.teamMessage1}
          teamMessage2={personalityData.teamMessage2}
          imgSource={'team.jpg'}
        />
      )}
    </View>
  )
}

export default React.memo(TeamSectionView)
