import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import App from './App'
import 'reset-css'
import './assets/scss/index.scss'

const rootEl = document.getElementById('root')
if (!rootEl) throw new Error('No #root element could be found')
createRoot(rootEl).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
