import React from 'react'
import store from '../../../store'
import QRCode from 'react-qr-code'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function FooterSection({ clickHandler }: Props) {
  const uiData = store((state) => state.uiData)
  const classes = classNames(styles['footer-section'])

  return (
    <div className={classes} onClick={clickHandler}>
      <div>{uiData['footer.text']}</div>
      <div className={styles.qrCode}>
        {/*@ts-ignore*/}
        <QRCode
          value={uiData['footer.cta.url']}
          bgColor="#000078"
          fgColor={'#ffffff'}
          size={170}
        />
      </div>
      {/*<div>{uiData['footer.cta.url']}</div>*/}
    </div>
  )
}

export default FooterSection
