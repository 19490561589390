import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Props } from './types'
import View from '../../views/view'
import OnsiteGroup from '../onsite-group'
import styles from './style.module.scss'

function OnsiteSection({ title, onsiteGroups }: Props) {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const observeElRef = useRef<HTMLDivElement | null>(null)
  const [ready, setReady] = useState<boolean>(false)
  const classes = classNames(styles['onsite-section'], ['results-section'], {
    [styles['ready']]: ready,
  })

  useEffect(() => {
    if (!observeElRef.current) return
    observerRef.current = new IntersectionObserver((entries) => {
      if (!!entries.find((el) => el.isIntersecting)) setReady(true)
    })
    observerRef.current?.observe(observeElRef.current)
    return () => observerRef.current?.disconnect()
    // eslint-disable-next-line
  }, [])

  return (
    <View extraClasses={classes} customRef={observeElRef}>
      <h2 className={styles.title}>{title}</h2>
      <View extraClasses={styles['scrollbar']} scrollableHorizontally={true}>
        {onsiteGroups?.map((object) => (
          <OnsiteGroup
            key={object.id}
            lead={object.attributes.lead}
            description={object.attributes.description}
            image={object.attributes.image}
            momentsGenerosities={object.attributes.momentsGenerosities}
          />
        ))}
      </View>
    </View>
  )
}

export default OnsiteSection
