import React, { useState } from 'react'
import classNames from 'classnames'
import { Props } from './types'
import styles from './style.module.scss'

function Button({
  type,
  label,
  clickHandler,
  extraClasses,
  isDisabled,
  hideOnClick,
}: Props) {
  const [isClicked, setIsClicked] = useState(false)
  const classes = classNames(
    styles.button,
    [styles[`type-${type}`]],
    {
      [styles['is-disabled']]: isDisabled,
      [styles['is-hiding']]: isClicked && hideOnClick,
    },
    extraClasses
  )

  return (
    <button
      className={classes}
      onClick={() => {
        clickHandler && clickHandler()
        setIsClicked(true)
      }}
    >
      {label}
    </button>
  )
}

export default Button
