import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import store from './store'
import { Routes, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import useUpdateResults from './hooks/useUpdateResults'
import Intro from './components/views/intro'
import Chat from './components/views/chat'
import Results from './components/views/results'
import Modal from './components/views/modal'

function App() {
  const location = useLocation()
  const results = store((state) => state.results)
  useUpdateResults(results)

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-TCM4PVM',
    }
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <div
      className="App"
      style={{
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: '#000078', // nachtblau
      }}
    >
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          classNames="main-pagination"
          timeout={1000}
        >
          <Routes location={location}>
            <Route path="/" element={<Intro />} />
            <Route path="chat" element={<Chat />} />
            <Route path="results" element={<Results />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Modal />
    </div>
  )
}

export default App
