import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Props } from './types'
import { IOption } from '../../atoms/filter-button/types'
import FilterButton from '../../atoms/filter-button'
import styles from './style.module.scss'

function InspirationFilter({ options, onSelected }: Props) {
  const [selectedItems, setSelectedItems] = useState<IOption[]>([options[0]])
  const classes = classNames(styles['inspiration-filter'])

  useEffect(() => {
    onSelected && onSelected(selectedItems)
    // eslint-disable-next-line
  }, [selectedItems])

  function handleClick(item: IOption) {
    if (item.value === null) {
      setSelectedItems([options[0]])
    } else {
      setSelectedItems((prevSelectedItems) => {
        if (
          prevSelectedItems.length === 1 &&
          prevSelectedItems[0].value === item.value
        ) {
          return [options[0]]
        }
        return options.filter((o) => o.value === item.value)
      })
    }
  }

  return (
    <div className={classes}>
      {options.map((option, index) => (
        <FilterButton
          key={index}
          option={option}
          isSelected={selectedItems.some((o) => o.value === option.value)}
          clickHandler={(item) => handleClick(item)}
        />
      ))}
    </div>
  )
}

export default InspirationFilter
