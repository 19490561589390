import React from 'react'
import store from '../../../store'
import { useNavigate } from 'react-router-dom'
import { Props } from './types'
import Icon from '../../atoms/icon'
import Pictogram from '../../atoms/pictogram'
import classNames from 'classnames'
import GenerosityOverlay from '../../organisms/generosity-overlay'
import styles from './style.module.scss'

function GenerosityTeaser({ title }: Props) {
  const navigate = useNavigate()
  const generosityData = store((state) => state.generosityData)
  const setModalContent = store((state) => state.setModalContent)
  const generosity = generosityData.find((i) => i.id === title)
  const pictogramName = `${generosity?.attributes.icon}-48`
  const overlayPictogramName = `${generosity?.attributes.icon}-100`

  const classes = classNames(styles['generosity-teaser'], ['generosity-teaser'])

  const modalContentSetter = () =>
    setModalContent(
      <GenerosityOverlay
        title={generosity?.attributes.title || ''}
        description={generosity?.attributes.content || ''}
        pictogramName={overlayPictogramName}
      />,
      `#generosity-${generosity?.id}`,
      () => navigate('/results'),
      'white',
      'generosity'
    )

  return (
    <div className={classes} onClick={() => modalContentSetter()}>
      {generosity && (
        <>
          <Pictogram name={pictogramName}></Pictogram>
          <div>{generosity.attributes.title}</div>
          <Icon iconName="plus-40" iconSize="small" />
        </>
      )}
    </div>
  )
}

export default GenerosityTeaser
