import React from 'react'
import classNames from 'classnames'
import { Props } from './types'
import Icon from '../../atoms/icon'
import styles from './style.module.scss'

function BookTeaser({ title, image, sortIndex, clickHandler }: Props) {
  const classes = classNames(styles['book-teaser'], [
    'type-book',
    'type-product', // should also be filterable by product
    'isotope-item',
  ])
  let imgSource
  try {
    imgSource = require(`../../../assets/img/inspiration/${image}`)
  } catch (error) {
    console.info('IMAGE NOT FOUND', image)
  }
  return (
    <div className={classes} onClick={clickHandler} data-sort-index={sortIndex}>
      <div className={styles.inner}>
        <div className={styles['image-container']}>
          <img src={imgSource} alt="" />
        </div>
        <div className={styles['content-container']}>
          <h3 className={styles.title}>{title}</h3>
          <Icon iconName="arrow-40--blue" />
        </div>
      </div>
    </div>
  )
}

export default BookTeaser
